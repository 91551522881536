<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Neues Video">
      <video-form
        :video="video"
        submit-label="Neues Video anlegen"
        :form-submitted="saveVideo"
        mode="new"
      ></video-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import VideoForm from "@/components/forms/VideoForm"
import VideosRepository from "@/repositories/videos_repository.js"

export default {
  metaInfo: {
    title: "Neues Video"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    VideoForm
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  data() {
    return {
      video: {
        video_category_id: this.$route.params.video_category_id
      },
      prevRoute: null
    }
  },
  async created() {},
  methods: {
    async saveVideo(video) {
      await VideosRepository.create(video)
      if (this.prevRoute) {
        this.$router.push(this.prevRoute)
      } else {
        this.$router.push({ name: "VideoCategoriesPage" })
      }
    }
  }
}
</script>
